import React from "react";
import NavBar from "../../components/navigation-header";
import "../../styling/templates.css";
import Footer from "../../components/footer";
import QuestionCard from "../../components/QuestionCard";
import BackgroundImage from "../../images/faq-background.webp";

const questions = [
  {
    question: "What is Eco Spindles?",
    answer:
      "Eco Spindles is a leading manufacturer of polyester yarn and mono-filaments in Sri Lanka, specializing in the use of recycled plastic waste to create high-quality, sustainable products.",
    no: 1,
  },
  {
    question: "How long has Eco Spindles been in business?",
    answer:
      "Eco Spindles has been at the forefront of sustainable manufacturing since 2011, transforming plastic waste into valuable products and contributing to environmental conservation.",
    no: 2,
  },
  {
    question: "What types of products does Eco Spindles manufacture?",
    answer:
      "We manufacture polyester yarn and mono-filaments that are used in a variety of applications including textiles, brushes, and brooms. Our products are made from recycled PET (Polyethylene Terephthalate) bottles.",
    no: 3,
  },
  {
    question: "Can Eco Spindles produce custom products?",
    answer:
      "Yes, we offer custom manufacturing services to meet specific requirements for yarn and mono-filament products. Please contact us to discuss your custom needs.",
    no: 4,
  },
  {
    question: "What sustainability initiatives does Eco Spindles undertake?",
    answer:
      "Eco Spindles is dedicated to sustainability through our extensive recycling programs. We collect and recycle PET bottles, converting them into high-quality yarn and mono-filaments, thereby reducing plastic pollution and supporting a circular economy.",
    no: 5,
  },
  {
    question: "How does Eco Spindles contribute to plastic recycling?",
    answer:
      "We operate a state-of-the-art recycling facility where we process PET bottles into raw material for our products. This initiative not only reduces plastic waste but also conserves resources by creating valuable products from recycled materials.",
    no: 6,
  },
  {
    question: "How does Eco Spindles ensure product quality?",
    answer:
      "We maintain strict quality control measures throughout the manufacturing process, ensuring our products meet international standards. Our commitment to quality is reflected in our ISO certifications and continuous investment in advanced technology.",
    no: 7,
  },
  {
    question: "What certifications does Eco Spindles hold?",
    answer:
      "Eco Spindles holds certifications such as ISO 9001 for quality management and ISO 14001 for environmental management, demonstrating our commitment to quality and sustainability.",
    no: 8,
  },
  {
    question: "Which countries does Eco Spindles export to?",
    answer:
      "We export our products to numerous countries around the world, including markets in North America, Europe, Asia, and Australia, meeting the global demand for sustainable yarn and mono-filaments.",
    no: 9,
  },
  {
    question: "How can I become a distributor for Eco Spindles products?",
    answer:
      "If you are interested in becoming a distributor, please contact our sales team through our website or email us at info@ecospindles.com. We will be happy to discuss partnership opportunities.",
    no: 10,
  },
  {
    question: "Does Eco Spindles invest in research and development?",
    answer:
      "Yes, we have a dedicated R&D team focused on innovation and improving our product offerings. Our efforts in research and development ensure we stay at the forefront of sustainable manufacturing technologies.",
    no: 11,
  },
  {
    question: "How can I apply for a job at Eco Spindles?",
    answer:
      "To apply, email your resume and cover letter to careers@ecospindles.com.",
    no: 12,
  },
  {
    question: "What kind of career opportunities does Eco Spindles offer?",
    answer:
      "We offer a wide range of career opportunities across various functions including manufacturing, engineering, sales and marketing, finance, and administration. Eco Spindles is committed to employee development and providing a supportive work environment.",
    no: 13,
  },
  {
    question: "How can I contact Eco Spindles for more information?",
    answer:
      "You can reach us through our website’s contact form, by emailing info@ecospindles.lk, or by calling our office at +94 11 230 7170. Our team is available to assist you with any inquiries.",
    no: 14,
  },
  {
    question: "Where is Eco Spindles located?",
    answer:
      "Eco Spindles is headquartered in Colombo, Sri Lanka, with our recycling and manufacturing facilities located in Horana. Visit our website for detailed contact information and directions.",
    no: 15,
  },
];
export default function FAQ() {
  return (
    <div>
      <NavBar />

      <div style={{ position: "relative" }}>
        <img className="home-image" alt="" src={BackgroundImage} />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "80vh",
            backgroundColor: "rgba(0, 0, 0, 0.24)",
            zIndex: 0,
          }}
        ></div>
        <div className="video-text-box">
          <div className="video-background-text">
            <h1 className="video-topic">
              FREQUENTLY <br />
              ASKED QUESTIONS
            </h1>
          </div>
        </div>
      </div>

      <div style={{ margin: "100px 0px" }}>
        <center>
          <p
            className="question-section-header"
            style={{
              width: "100%",
              height: "auto",
              padding: "0px 8% 60px 8%",
            }}
          >
            ABOUT US
          </p>
          {questions.map((question, index) => {
            return question.no <= 10 ? <QuestionCard props={question} /> : "";
          })}
          <p
            className="question-section-header"
            style={{
              width: "100%",
              height: "auto",
              padding: "120px 8% 60px 8%",
            }}
          >
            RESEARCH AND DEVELOPMENT
          </p>
          {questions.map((question, index) => {
            return question.no > 10 && question.no <= 13 ? (
              <QuestionCard props={question} />
            ) : (
              ""
            );
          })}
          <p
            className="question-section-header"
            style={{
              width: "100%",
              height: "auto",
              padding: "120px 8% 60px 8%",
            }}
          >
            CONTACT AND SUPPORT
          </p>
          {questions.map((question, index) => {
            return question.no > 13 ? <QuestionCard props={question} /> : "";
          })}
          {/* {questions.map((question, index) => {
            return <QuestionCard props={question} />;
          })} */}
        </center>
      </div>
      <Footer />
    </div>
  );
}
