import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

export default function QuestionCard({ props }) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    console.log(isOpen);
  }, [isOpen]);
  return (
    <div
      className="question-container"
      style={{
        width: "80%",
        minWidth: "280px",
        height: "auto",
        display: "flex",
        borderTop: "1px solid #006936 ",
        justifyContent: "center",
        alignItems: "start",
        flexDirection: "column",
        padding: "30px 20px",
        backgroundColor: "white",
        margin: "0px",
        cursor: "pointer",
        userSelect: "none", // Disables text selection
        WebkitUserSelect: "none", // Disables text selection in WebKit browsers
        MozUserSelect: "none", // Disables text selection in Firefox
        msUserSelect: "none", // Disables text selection in IE/Edge
      }}
    >
      <div className="question-header" onClick={() => setIsOpen(!isOpen)}>
        <h1>
          {props.no}
          {". "}
          {props.question}
        </h1>
        <FontAwesomeIcon
          icon={isOpen ? faMinus : faPlus}
          style={{ height: "0.9em", color: "#222831" }}
        />
      </div>
      {isOpen ? (
        <div
          className="answer-container"
          style={{
            width: "100%",
            height: "auto",
            marginTop: "20px",
            color: "#61677A",
          }}
        >
          {props.answer}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
